@media only screen and (min-width: 1400px){
    .container {
        position: relative;
        height: 100%;
        width: 100%;
        display: flex;
        justify-content: flex-end;
        align-items: center;
    }

    .card {
        display: flex;
        height: 600px;
        width: 34%;
        background-color: #000000;
        border-radius: 10px;
        box-shadow: -0.7rem 0 3rem #000;
        /*   margin-left: -50px; */
        transition: 0.4s ease-out;
        position: relative;
        left: 0;
    }

    .card:not(:first-child) {
        margin-left: -50px;
    }

    .card:hover {
        transform: translateY(-20px);
        transition: 0.4s ease-out;
    }

    .card:hover ~ .card {
        position: relative;
        left: 50px;
        transition: 0.4s ease-out;
    }

    .title {
        color: white;
        font-weight: 300;
        position: absolute;
        left: 20px;
        top: 15px;
    }
}

@media only screen and (min-width: 1200px) and (max-width: 1399px){
    .container {
        position: relative;
        height: 100%;
        width: 100%;
        display: flex;
        justify-content: flex-end;
        align-items: center;
    }

    .card {
        display: flex;
        height: 450px;
        width: 34%;
        background-color: #000000;
        border-radius: 10px;
        box-shadow: -0.7rem 0 3rem #000;
        /*   margin-left: -50px; */
        transition: 0.4s ease-out;
        position: relative;
        left: 0;
    }

    .card:not(:first-child) {
        margin-left: -50px;
    }

    .card:hover {
        transform: translateY(-20px);
        transition: 0.4s ease-out;
    }

    .card:hover ~ .card {
        position: relative;
        left: 50px;
        transition: 0.4s ease-out;
    }

    .title {
        color: white;
        font-weight: 300;
        position: absolute;
        left: 20px;
        top: 15px;
    }
}

@media only screen and (min-width: 992px) and (max-width: 1199px){
    .container {
        position: relative;
        height: 100%;
        width: 100%;
        display: flex;
        justify-content: flex-end;
        align-items: center;
    }

    .card {
        display: flex;
        height: 450px;
        width: 34%;
        background-color: #000000;
        border-radius: 10px;
        box-shadow: -0.7rem 0 3rem #000;
        /*   margin-left: -50px; */
        transition: 0.4s ease-out;
        position: relative;
        left: 0;
    }

    .card:not(:first-child) {
        margin-left: -50px;
    }

    .card:hover {
        transform: translateY(-20px);
        transition: 0.4s ease-out;
    }

    .card:hover ~ .card {
        position: relative;
        left: 50px;
        transition: 0.4s ease-out;
    }

    .title {
        color: white;
        font-weight: 300;
        position: absolute;
        left: 20px;
        top: 15px;
    }
}

@media only screen and (min-width: 768px) and (max-width: 991px){
    .container {
        position: relative;
        height: 100%;
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .card {
        display: flex;
        height: 320px;
        width: 34%;
        background-color: #000000;
        border-radius: 10px;
        box-shadow: -0.7rem 0 3rem #000;
        /*   margin-left: -50px; */
        transition: 0.4s ease-out;
        position: relative;
        left: 0;
    }

    .card:not(:first-child) {
        margin-left: -50px;
    }

    .card:hover {
        transform: translateY(-20px);
        transition: 0.4s ease-out;
    }

    .card:hover ~ .card {
        position: relative;
        left: 50px;
        transition: 0.4s ease-out;
    }

    .title {
        color: white;
        font-weight: 300;
        position: absolute;
        left: 20px;
        top: 15px;
    }
}

@media only screen and (min-width: 576px) and (max-width: 767px){
    .container {
        position: relative;
        height: 100%;
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .card {
        display: flex;
        height: 340px;
        width: 34%;
        background-color: #000000;
        border-radius: 10px;
        box-shadow: -0.7rem 0 3rem #000;
        /*   margin-left: -50px; */
        transition: 0.4s ease-out;
        position: relative;
        left: 0;
    }

    .card:not(:first-child) {
        margin-left: -50px;
    }

    .card:hover {
        transform: translateY(-20px);
        transition: 0.4s ease-out;
    }

    .card:hover ~ .card {
        position: relative;
        left: 50px;
        transition: 0.4s ease-out;
    }

    .title {
        color: white;
        font-weight: 300;
        position: absolute;
        left: 20px;
        top: 15px;
    }
}

@media only screen and (min-width: 330px) and (max-width: 575px){
    .container {
        position: relative;
        height: 100%;
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .card {
        display: flex;
        height: 250px;
        width: 50%;
        background-color: #000000;
        border-radius: 10px;
        box-shadow: -0.7rem 0 3rem #000;
        /*   margin-left: -50px; */
        transition: 0.4s ease-out;
        position: relative;
        left: 0;
    }

    .card:not(:first-child) {
        margin-left: -50px;
    }

    .card:hover {
        transform: translateY(-20px);
        transition: 0.4s ease-out;
    }

    .card:hover ~ .card {
        position: relative;
        left: 50px;
        transition: 0.4s ease-out;
    }

    .title {
        color: white;
        font-weight: 300;
        position: absolute;
        left: 20px;
        top: 15px;
    }
}

@media only screen and (max-width: 329px){
    .container {
        position: relative;
        height: 100%;
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .card {
        display: flex;
        height: 250px;
        width: 50%;
        background-color: #000000;
        border-radius: 10px;
        box-shadow: -0.7rem 0 3rem #000;
        /*   margin-left: -50px; */
        transition: 0.4s ease-out;
        position: relative;
        left: 0;
    }

    .card:not(:first-child) {
        margin-left: -50px;
    }

    .card:hover {
        transform: translateY(-20px);
        transition: 0.4s ease-out;
    }

    .card:hover ~ .card {
        position: relative;
        left: 50px;
        transition: 0.4s ease-out;
    }

    .title {
        color: white;
        font-weight: 300;
        position: absolute;
        left: 20px;
        top: 15px;
    }
}