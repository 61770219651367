.styled-icon{
    display: flex;
    align-items: center;
}

.icon-s{
    font-size: 70px;
    margin-bottom: 14px;
}

.icon-form-m{
    font-size: 60px;
    margin-bottom: 14px;
}

.icon-form-s{
    font-size: 40px;
    margin-bottom: 14px;
}

.icon-m{
    font-size: 70px;
    margin-bottom: 24px;
}

.icon-l{
    font-size: 100px;
    margin-bottom: 24px;
}

.icon-text-l{
    font-size: 24px;
}

.icon-text-m{
    font-size: 16px;
}

.icon-text-service{
    font-size: 40px;
}

.icon-text-s{
    font-size: 20px;
    margin-bottom: 50px;
}

.messages-container{
    display: flex;
    flex-direction: column;
}
