*{
    margin: 0;
    padding: 0;
}

.rep-container{
    background: black;
    padding-bottom: 50px;
}

.intro-rep-section{
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.images-intro{
    width: 80%;
    height: auto;
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
}

.title-rep{
    color: #fcfcfc;
    font-weight: lighter;
    font-size: 80px;
    margin-bottom: 30px;
}

.subtitle-rep{
    color: white;
    font-size: 32px;
    margin-bottom: 42px;
}

@media only screen and (min-width: 1200px) and (max-width: 1399px){
    .title-rep{
        font-size: 65px;
    }

    .subtitle-rep{
        font-size: 28px;
    }
}

@media only screen and (min-width: 992px) and (max-width: 1199px){
    .title-rep{
        font-size: 60px;
    }

    .subtitle-rep{
        font-size: 26px;
    }
}

@media only screen and (min-width: 768px) and (max-width: 991px){
    .title-rep{
        font-size: 50px;
    }

    .subtitle-rep{
        font-size: 22px;
    }
}

@media only screen and (min-width: 576px) and (max-width: 767px){
    .title-rep{
        font-size: 40px;
    }

    .subtitle-rep{
        font-size: 20px;
    }
}

@media only screen and (min-width: 330px) and (max-width: 575px){
    .images-intro{
        width: 100%;
        flex-direction: column;
    }

    .rep-section{
        display: flex;
        justify-content: center;
    }

    .intro-rep-section{
        width: 80%;
    }

    .title-rep{
        font-size: 50px;
        text-align: center;
        width: 100%;
    }

    .subtitle-rep{
        font-size: 20px;
        text-align: center;
    }
}

@media only screen and (max-width: 329px){
    .images-intro{
        width: 100%;
        flex-direction: column;
    }

    .rep-section{
        display: flex;
        justify-content: center;
    }

    .intro-rep-section{
        width: 80%;
    }

    .title-rep{
        font-size: 44px;
        text-align: center;
        width: 100%;
    }

    .subtitle-rep{
        font-size: 14px;
        text-align: center;
    }
}