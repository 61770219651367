.map{
    width: 100%;
    height: 110vh;
    padding-top: 100px;
}

.map-title{
    margin-left: 10%;
    height: 10%;
    display: flex;
    align-items: center;
}

.map-title h2{
    font-weight: lighter;
    font-size: 46px;
}

@media only screen and (min-width: 768px) and (max-width: 992px){
    .map-title{
        margin-bottom: 10px;
    }

    .map-title h2{
        font-size: 38px;
    }
}

@media only screen and (min-width: 576px) and (max-width: 767px){
    .map-title{
        margin-bottom: 10px;
    }

    .map-title h2{
        font-size: 36px;
        width: 80%;
    }
}

@media only screen and (min-width: 330px) and (max-width: 575px){
    .map-title{
        margin-bottom: 10px;
    }

    .map-title h2{
        font-size: 32px;
        width: 95%;
    }
}

@media only screen and (max-width: 329px){
    .map-title{
        margin-bottom: 10px;
    }

    .map-title h2{
        font-size: 28px;
        width: 100%;
    }
}