*{
    margin: 0;
    padding: 0;
}

.header-container{
    width: 100%;
    height: 90px;
    padding-bottom: 10px;
    display: flex;
    justify-content: center;
}

.header-section{
    width: 80%;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.title-header{
    width: 20%;
}

.title-header-link{
    text-decoration: none;
    font-size: 18px;
    font-weight: bold;
}

.link-list{
    display: flex;
    justify-content: space-between;
}

ul{
    list-style-type: none;
}

.link{
    text-decoration: none;
    font-size: 15px;
    color: white;
    font-weight: bold;
}

.link:hover{
    color: #fed403;
}

.link-white{
    text-decoration: none;
    font-size: 15px;
    color: #000000;
    font-weight: bold;
}

.link-white:hover{
    color: #fed403;
}

.link-white-color-black{
    text-decoration: none;
    font-size: 15px;
    color: #ffffff;
    font-weight: bold;
}

.link-white-color-black:hover{
    color: #fed403;
}

.link-absolute-color-black{
    text-decoration: none;
    font-size: 15px;
    color: #000000;
    font-weight: bold;
}

.link-absolute-color-black:hover{
    color: #fed403;
}

.complete-menu{
    width: 100%;
    height: 100%;
    background-color: #000000;
    position: absolute;
    display: flex;
    flex-direction: column;
    z-index: 1;
}

.complete-menu-x{
    height: 10%;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding-right: 30px;
}

.complete-menu-options{
    height: 90vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
}

.links-options{
    text-decoration: none;
    font-size: 30px;
    color: white;
    font-weight: bold;
}

.link-list-options{
    height: 30vh;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.icons-menu{
    height: 5%;
    display: flex;
    align-items: flex-end;
    margin-left: 20px;
}

@media only screen and (min-width: 1400px){
    .links-header{
        width: 35%;
    }
}

@media only screen and (min-width: 1200px) and (max-width: 1399px){
    .links-header{
        width: 35%;
    }
}

@media only screen and (min-width: 992px) and (max-width: 1199px){
    .links-header{
        width: 35%;
    }
}

@media only screen and (min-width: 768px) and (max-width: 991px){
    .links-header{
        width: 50%;
    }
}

@media only screen and (min-width: 576px) and (max-width: 767px){
    .links-header{
        width: 35%;
    }

    .link-list{
        display: none;
    }
}

@media only screen and (max-width: 575px){
    .links-header{
        width: 35%;
    }

    .link-list{
        display: none;
    }

    .title-header{
        width: 50%;
    }
}