@media only screen and (min-width: 1400px){
    .intro-box-container{
        width: 32%;
        height: 92%;
        display: flex;
        align-items: flex-end;
        justify-content: flex-end;
    }

    .intro-box-shadow{
        background-color: #fed403;
        position: absolute;
        width: 24%;
        height: 54%;
    }

    .intro-box{
        background-color: #FFF7CE;
        position: absolute;
        width: 24%;
        height: 54%;
        display: flex;
        align-self: flex-start;
        margin-right: 20px;
        align-items: flex-end;
        text-decoration: none;
        color: black;
    }

    .intro-box h2{
        margin-left: 20px;
        width: 88%;
        text-decoration: none;
        display: flex;
        font-weight: lighter;
        font-size: 30px;
        align-items: flex-end;
        margin-bottom: 20px;
        text-decoration: none;
    }
}

@media only screen and (min-width: 1200px) and (max-width: 1399px){
    .intro-box-container{
        width: 32%;
        height: 92%;
        display: flex;
        align-items: flex-end;
        justify-content: flex-end;
    }

    .intro-box-shadow{
        background-color: #fed403;
        position: absolute;
        width: 24%;
        height: 54%;
    }

    .intro-box{
        background-color: #FFF7CE;
        position: absolute;
        width: 24%;
        height: 54%;
        display: flex;
        align-self: flex-start;
        margin-right: 20px;
        align-items: flex-end;
        text-decoration: none;
        color: black;
    }

    .intro-box h2{
        margin-left: 20px;
        width: 88%;
        display: flex;
        font-weight: lighter;
        font-size: 30px;
        align-items: flex-end;
        margin-bottom: 20px;
    }
}

@media only screen and (min-width: 992px) and (max-width: 1199px){
    .intro-box-container{
        width: 32%;
        height: 92%;
        display: flex;
        align-items: flex-end;
        justify-content: flex-end;
    }

    .intro-box-shadow{
        background-color: #fed403;
        position: absolute;
        width: 24%;
        height: 54%;
    }

    .intro-box{
        background-color: #FFF7CE;
        position: absolute;
        width: 24%;
        height: 54%;
        display: flex;
        align-self: flex-start;
        margin-right: 20px;
        align-items: flex-end;
        text-decoration: none;
        color: black;
    }

    .intro-box h2{
        margin-left: 20px;
        width: 88%;
        display: flex;
        font-weight: lighter;
        font-size: 30px;
        align-items: flex-end;
        margin-bottom: 20px;
    }
}

@media only screen and (min-width: 768px) and (max-width: 991px){
    .intro-box-container{
        width: 100%;
        height: 30%;
        display: flex;
    }

    .intro-box-shadow{
        background-color: #fed403;
        position: absolute;
        width: 79%;
        height: 18%;
        margin-left: 10px;
    }

    .intro-box{
        background-color: #FFF7CE;
        position: absolute;
        width: 79%;
        height: 17%;
        display: flex;
        align-self: flex-start;
        margin-right: 20px;
        align-items: flex-end;
        text-decoration: none;
        color: black;
    }

    .intro-box h2{
        margin-left: 20px;
        width: 88%;
        display: flex;
        font-weight: lighter;
        font-size: 30px;
        align-items: flex-end;
        margin-bottom: 20px;
    }
}

@media only screen and (min-width: 576px) and (max-width: 767px){
    .intro-box-container{
        width: 100%;
        height: 30%;
        display: flex;
    }

    .intro-box-shadow{
        background-color: #fed403;
        position: absolute;
        width: 79%;
        height: 18%;
        margin-left: 10px;
    }

    .intro-box{
        background-color: #FFF7CE;
        position: absolute;
        width: 79%;
        height: 17%;
        display: flex;
        align-self: flex-start;
        margin-right: 20px;
        align-items: flex-end;
        text-decoration: none;
        color: black;
    }

    .intro-box h2{
        margin-left: 20px;
        width: 88%;
        display: flex;
        font-weight: lighter;
        font-size: 30px;
        align-items: flex-end;
        margin-bottom: 20px;
    }
}

@media only screen and (min-width: 330px) and (max-width: 575px){
    .intro-box-container{
        width: 100%;
        height: 30%;
        display: flex;
    }

    .intro-box-shadow{
        background-color: #fed403;
        position: absolute;
        width: 79%;
        height: 18%;
        margin-left: 10px;
    }

    .intro-box{
        background-color: #FFF7CE;
        position: absolute;
        width: 79%;
        height: 17%;
        display: flex;
        align-self: flex-start;
        margin-right: 20px;
        align-items: flex-end;
        text-decoration: none;
        color: black;
    }

    .intro-box h2{
        margin-left: 20px;
        width: 88%;
        display: flex;
        font-weight: lighter;
        font-size: 26px;
        align-items: flex-end;
        margin-bottom: 20px;
    }
}

@media only screen and (max-width: 329px){
    .intro-box-container{
        width: 100%;
        height: 30%;
        display: flex;
    }

    .intro-box-shadow{
        background-color: #fed403;
        position: absolute;
        width: 79%;
        height: 18%;
        margin-left: 10px;
    }

    .intro-box{
        background-color: #FFF7CE;
        position: absolute;
        width: 79%;
        height: 17%;
        display: flex;
        align-self: flex-start;
        margin-right: 20px;
        align-items: flex-end;
        text-decoration: none;
        color: black;
    }

    .intro-box h2{
        margin-left: 20px;
        width: 88%;
        display: flex;
        font-weight: lighter;
        font-size: 22px;
        align-items: flex-end;
        margin-bottom: 5px;
    }
}
