.work-container{
    background-color: #FAFAFA;
    width: 100%;
    height: 730px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding-bottom: 50px;
}

.work-section{
    display: flex;
    width: 80%;
    height: 90%;
}

.process-section{
    display: flex;
    flex-direction: column;
    width: 70%;
    height: 100%;
    justify-content: space-between;
}

.work-titles-box{
    width: 30%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.process-box{
    width: 80%;
    height: 200px;
    background-color: #fed403;
    display: flex;
    justify-content: center;
    align-items: center;
    color: black;
}

.work-title{
    font-size: 60px;
    font-weight: lighter;
    margin-bottom: 20px;
}

.work-desc{
    font-size: 22px;
    margin-top: 20px;
    margin-bottom: 30px;
    text-align: justify;
}

.process-box-info{
    display: flex;
    height: 90%;
    width: 90%;
    justify-content: space-between;
}

.process-box-info-text{
    width: 75%;
    height: 100%;
    display: flex;
    align-items: center;
}

.process-box-info-text p{
    font-size: 22px;
}

@media only screen and (min-width: 1200px) and (max-width: 1399px){
    .work-title{
        font-size: 56px;
    }

    .work-desc{
        font-size: 20px;
    }
}

@media only screen and (min-width: 992px) and (max-width: 1199px){
    .work-title{
        font-size: 54px;
    }

    .work-desc{
        font-size: 18px;
    }

    .process-box-info-text{
        width: 65%;
        height: 100%;
        display: flex;
        align-items: center;
    }

    .process-box-info-text p{
        font-size: 20px;
    }
}

@media only screen and (min-width: 768px) and (max-width: 991px){
    .work-title{
        font-size: 50px;
    }

    .work-desc{
        font-size: 16px;
    }

    .process-box-info-text{
        width: 65%;
        height: 100%;
        display: flex;
        align-items: center;
    }

    .process-box-info-text p{
        font-size: 18px;
    }
}

@media only screen and (min-width: 576px) and (max-width: 767px){
    .work-container{
        height: 700px;
    }

    .work-section{
        flex-direction: column;
        justify-content: space-between;
    }

    .process-section{
        flex-direction: row;
        width: 100%;
        height: 45%;
    }

    .process-box{
        width: 30%;
        height: 100%;
    }

    .work-titles-box{
        width: 100%;
        height: 50%;
        align-items: center;
    }

    .work-title{
        font-size: 48px;
        text-align: center;
    }

    .work-desc{
        font-size: 16px;
        width: 80%;
        text-align: center;
    }

    .process-box-info{
        display: flex;
        height: 90%;
        width: 90%;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        padding-top: 20px;
    }

    .process-box-info-text{
        width: 100%;
        height: 100%;
        display: flex;
    }

    .process-box-info-text p{
        font-size: 18px;
        text-align: center;
    }
}

@media only screen and (min-width: 330px) and (max-width: 575px){
    .work-container{
        width: 100%;
        height: 730px;
        padding-bottom: 0;
    }

    .work-section{
        flex-direction: column;
        justify-content: space-between;
    }

    .process-section{
        width: 100%;
        height: 60%;
    }

    .process-box{
        width: 100%;
        height: 30%;
    }

    .work-titles-box{
        width: 100%;
        height: 100%;
        align-items: center;
    }

    .work-title{
        font-size: 44px;
        text-align: center;
    }

    .work-desc{
        font-size: 18px;
        width: 80%;
        text-align: center;
    }

    .process-box-info{
        display: flex;
        height: 90%;
        width: 90%;
        justify-content: space-between;
    }

    .process-box-info-text{
        width: 70%;
        height: 100%;
        display: flex;
        align-items: center;
    }

    .process-box-info-text p{
        font-size: 17px;
        padding-top: 12px;
    }
}

@media only screen and (max-width: 329px){
    .work-container{
        width: 100%;
        height: 700px;
    }

    .work-section{
        flex-direction: column;
        justify-content: space-between;
    }

    .process-section{
        width: 100%;
        height: 45%;
    }

    .process-box{
        width: 100%;
        height: 30%;
    }

    .work-titles-box{
        width: 100%;
        height: 50%;
        align-items: center;
    }

    .work-title{
        font-size: 44px;
        text-align: center;
    }

    .work-desc{
        font-size: 15px;
        width: 80%;
        text-align: center;
    }
}