.image-container{
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 33%;
}

.image-container h3{
    margin-bottom: 14px;
    font-weight: lighter;
    color: white;
    font-size: 24px;
}

.image-box{
    display: flex;
    width: 100%;
    height: 450px;
}

.image{
    width: 100%;
    height: 100%;
}

@media only screen and (min-width: 1200px) and (max-width: 1399px){
    .image-container{
        width: 32%;
    }

    .image-box{
        height: 400px;
    }
}

@media only screen and (min-width: 992px) and (max-width: 1199px){
    .image-container{
        width: 32%;
    }

    .image-box{
        height: 400px;
    }
}

@media only screen and (min-width: 768px) and (max-width: 991px){
    .image-container{
        width: 32%;
    }

    .image-box{
        height: 400px;
    }
}

@media only screen and (min-width: 576px) and (max-width: 767px){
    .image-container{
        width: 32%;
    }

    .image-box{
        height: 400px;
    }
}

@media only screen and (min-width: 330px) and (max-width: 575px){
    .image-container{
        width: 100%;
        margin-bottom: 10px;
    }

    .image-box{
        height: 80px;
    }

    .image-container h3{
        font-size: 20px;
    }
}