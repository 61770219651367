.projects-container{
    width: 100%;
    height: auto;
    background: #FAFAFA;
    display: flex;
    justify-content: center;
    padding-bottom: 80px;
}

.projects-section{
    width: 80%;
}

.title-project{
    font-size: 60px;
    font-weight: lighter;
}

.projects-title{
    margin-top: 50px;
}

.projects-title p{
    font-size: 22px;
    margin-top: 30px;
    width: 40%;
    text-align: justify;
}

.carousel-section{
    height: 70%;
    margin-top: 30px;
}

.images-gallery{
    display: flex;
    width: 100%;
    justify-content: space-between;
}

@media screen and (min-width: 1200px) and (max-width: 1399px){
    .title-project{
        font-size: 56px;
        font-weight: lighter;
    }

    .projects-title p{
        font-size: 20px;
        margin-top: 30px;
        width: 350px;
    }
}

@media screen and (min-width: 992px) and (max-width: 1199px){
    .title-project{
        font-size: 54px;
        font-weight: lighter;
    }

    .projects-title p{
        font-size: 18px;
        margin-top: 30px;
        width: 40%;
        text-align: justify;
    }
}

@media screen and (min-width: 768px) and (max-width: 991px){
    .title-project{
        font-size: 50px;
        font-weight: lighter;
    }

    .projects-title p{
        font-size: 16px;
        margin-top: 30px;
        width: 50%;
        text-align: justify;
    }
}

@media screen and (min-width: 576px) and (max-width: 767px){
    .projects-container{
        padding-bottom: 40px;
    }

    .title-project{
        font-size: 48px;
        font-weight: lighter;
    }

    .projects-title p{
        font-size: 16px;
        margin-top: 30px;
        width: 90%;
        text-align: justify;
    }
}

@media screen and (min-width: 330px) and (max-width: 575px){
    .projects-container{
        height: auto;
        padding-bottom: 30px;
    }

    .title-project{
        font-size: 44px;
        font-weight: lighter;
    }

    .projects-title p{
        font-size: 18px;
        margin-top: 30px;
        width: 100%;
        text-align: justify;
    }
}

@media screen and (max-width: 329px){
    .projects-container{
        padding-bottom: 50px;
    }

    .title-project{
        font-size: 44px;
        font-weight: lighter;
    }

    .projects-title p{
        font-size: 15px;
        margin-top: 30px;
        width: 90%;
        text-align: justify;
    }

    .carousel-section{
        justify-content: space-evenly;
    }
}