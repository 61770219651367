.mesg-box{
    width: 70%;
    height: 180px;
    background-color: #FFFFFF;
    box-shadow: rgba(0, 0, 0, 0.24) 0 3px 8px;
    border: none;
    border-radius: 5px;
    display: flex;
    flex-direction: column;
    margin-bottom: 20px;
}

.info-mesg{
    width: 90%;
    height: 70%;
    padding-left: 30px;
}

.info-mesg h2{
    font-size: 24px;
    margin-top: 20px;
    margin-bottom: 10px;
}

.footer-mesg{
    display: flex;
    padding-left: 30px;
    padding-right: 30px;
    justify-content: space-between;
    height: 30%;
    align-items: center;
}

.footer-buttons{
    width: 40%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.button-msg{
    font-weight: bold;
    border: none;
    border-radius: 5px;
    width: 180px;
    height: 34px;
    font-size: 15px;
    margin-right: 10px;
    text-decoration: none;
    display: flex;
    align-items: center;
    justify-content: center;
}

@media only screen and (max-width: 576px){
    .mesg-box{
        width: 95%;
    }

    .info-mesg p{
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
    }
}