.service-image-container{
    display: flex;
}

.service-image{
    width: 310px;
    height: 330px;
    background-color: #fed403;
}

.image-service-desc{
    padding-left: 10px;
    width: 50%;
}

.image-service-desc h2{
    font-size: 28px;
}

.image-service-desc p{
    font-size: 18px;
}

.options{
    color: black;
    font-size: 18px;
}

@media only screen and (min-width: 2000px){
    .service-image{
        width: 390px;
        height: 500px;
    }
}

@media only screen and (min-width: 992px) and (max-width: 1199px){
    .service-image{
        width: 290px;
        height: 330px;
    }

    .image-service-desc h2{
        font-size: 26px;
    }

    .image-service-desc p{
        font-size: 16px;
    }

    .options{
        color: black;
        font-size: 17px;
    }
}

@media only screen and (min-width: 768px) and (max-width: 991px){
    .service-image-container{
        display: flex;
        height: 100%;
    }

    .service-image{
        width: 45%;
        height: 98%;
    }

    .image-service-desc h2{
        font-size: 24px;
    }

    .image-service-desc p{
        font-size: 16px;
    }

    .options{
        color: black;
        font-size: 14px;
    }
}

@media only screen and (min-width: 576px) and (max-width: 767px){
    .service-image-container{
        display: flex;
        height: 100%;
        margin-bottom: 20px;
    }

    .service-image{
        width: 45%;
        height: 98%;
    }

    .image-service-desc h2{
        font-size: 22px;
    }

    .image-service-desc p{
        font-size: 15px;
    }

    .options{
        color: black;
        font-size: 14px;
    }
}

@media only screen and (min-width: 330px) and (max-width: 575px){
    .service-image-container{
        display: flex;
        height: 100%;
        padding-bottom: 20px;
    }

    .service-image{
        width: 45%;
        height: 98%;
        display: none;
    }

    .image-service-desc{
        padding-left: 10px;
        width: 100%;
    }

    .image-service-desc h2{
        font-size: 24px;
    }

    .image-service-desc p{
        font-size: 18px;
    }

    .options{
        font-size: 18px;
        margin-bottom: 5px;
    }
}

@media only screen and (max-width: 329px){
    .service-image-container{
        display: flex;
        height: 100%;
    }

    .service-image{
        width: 45%;
        height: 98%;
        display: none;
    }

    .image-service-desc h2{
        font-size: 22px;
    }

    .image-service-desc p{
        font-size: 16px;
    }
}