.about-container{
    width: 100%;
    height: 100vh;
}

.about-section{
    display: flex;
    flex-direction: column;
    width: 100%;
}

.vision-section{
    width: 100%;
    background-color: #FFEA84;
    height: 45vh;
}

.mission-section{
    width: 100%;
    background-color: #FFF7CE;
    height: 45vh;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
}

.title-section{
    width: 100%;
    height: 10vh;
    display: flex;
    justify-content: center;
    align-items: center;
}

@media only screen and (min-width: 1200px) and (max-width: 1800px){
    .vision-section h1{
        margin-top: 10%;
        margin-left: 10%;
        font-size: 28px;
    }

    .vision-section p{
        margin-left: 10%;
        font-size: 20px;
        width: 60%;
        text-align: justify;
    }

    .mission-section h1{
        margin-top: 10%;
        margin-right: 10%;
        font-size: 28px;
    }

    .mission-section p{
        margin-right: 10%;
        font-size: 20px;
        width: 60%;
        text-align: justify;
    }
}

@media only screen and (min-width: 768px) and (max-width: 1199px){
    .vision-section h1{
        margin-top: 10%;
        margin-left: 10%;
        font-size: 24px;
    }

    .vision-section p{
        margin-left: 10%;
        font-size: 16px;
        width: 60%;
        text-align: justify;
    }

    .mission-section h1{
        margin-top: 10%;
        margin-right: 10%;
        font-size: 24px;
    }

    .mission-section p{
        margin-right: 10%;
        font-size: 16px;
        width: 60%;
        text-align: justify;
    }
}

@media only screen and (min-width: 576px) and (max-width: 768px){
    .vision-section h1{
        margin-top: 15%;
        margin-left: 10%;
        font-size: 24px;
    }

    .vision-section p{
        margin-left: 10%;
        font-size: 16px;
        width: 60%;
        text-align: justify;
    }

    .mission-section h1{
        margin-top: 10%;
        margin-right: 10%;
        font-size: 24px;
    }

    .mission-section p{
        margin-right: 10%;
        font-size: 16px;
        width: 60%;
        text-align: justify;
    }
}

@media only screen and (min-width: 330px) and (max-width: 575px){
    .vision-section h1{
        margin-top: 20%;
        margin-left: 10%;
        font-size: 24px;
    }

    .vision-section p{
        margin-left: 10%;
        font-size: 16px;
        width: 80%;
        text-align: justify;
    }

    .mission-section h1{
        margin-top: 10%;
        margin-right: 10%;
        font-size: 24px;
    }

    .mission-section p{
        margin-right: 10%;
        font-size: 16px;
        width: 80%;
        text-align: justify;
    }
}

@media only screen and (max-width: 329px){
    .vision-section h1{
        margin-top: 20%;
        margin-left: 10%;
        font-size: 20px;
    }

    .vision-section p{
        margin-left: 10%;
        font-size: 13px;
        width: 80%;
        text-align: justify;
    }

    .mission-section h1{
        margin-top: 10%;
        margin-right: 10%;
        font-size: 20px;
    }

    .mission-section p{
        margin-right: 10%;
        font-size: 13px;
        width: 80%;
        text-align: justify;
    }
}