.contact-container{
    width: 100%;
    height: auto;
    background-color: #fafafa;
    display: flex;
    justify-content: center;
    padding-bottom: 70px;
    padding-top: 50px;
}

.contact-section{
    width: 80%;
    height: 100%;
    display: flex;
    justify-content: space-between;
}

.contact-form{
    width: 60%;
    height: 800px;
    display: flex;
    justify-content: center;
    align-self: center;
}

.contact-title{
    width: 30%;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.contact-title h1{
    font-size: 56px;
    font-weight: lighter;
    margin-bottom: 20px;
}

.contact-title p{
    font-size: 20px;
    margin-top: 30px;
}

@media only screen and (min-width: 1400px) {
    .contact-container{
        height: 80vh;
    }
}

@media only screen and (min-height: 701px) {
    .contact-container{
        padding-top: 0;
        padding-bottom: 50px;
    }
}

@media only screen and (min-width: 576px) and (max-width: 767px){
    .contact-section{
        flex-direction: column;
    }

    .contact-form{
        width: 100%;
        height: 800px;
        display: flex;
        justify-content: center;
        align-self: center;
    }

    .contact-title{
        width: 100%;
        height: 50%;
    }

    .contact-title h1{
        font-size: 50px;
        font-weight: lighter;
        margin-bottom: 10px;
    }

    .contact-title p{
        font-size: 16px;
        margin-top: 0;
    }

    .phone-box{
        display: flex;
        width: 100%;
        align-items: center;
    }

}

@media only screen and (min-width: 330px) and (max-width: 575px){
    .contact-section{
        flex-direction: column;
    }

    .contact-form{
        width: 100%;
        height: auto;
        display: flex;
        justify-content: center;
        align-self: center;
    }

    .contact-title{
        width: 100%;
        height: 50%;
    }

    .contact-title h1{
        font-size: 50px;
        font-weight: lighter;
        margin-bottom: 10px;
    }

    .contact-title p{
        font-size: 18px;
        margin-top: 0;
    }

    .phone-box{
        margin-top: 20px;
        display: flex;
        flex-direction: column;
        width: 100%;
        height: 80px;
        align-items: center;
    }
}

@media only screen and (max-width: 329px){
    .contact-section{
        flex-direction: column;
    }

    .contact-form{
        width: 100%;
        height: 800px;
        display: flex;
        justify-content: center;
        align-self: center;
    }

    .contact-title{
        width: 100%;
        height: 50%;
    }

    .contact-title h1{
        font-size: 50px;
        font-weight: lighter;
        margin-bottom: 10px;
    }

    .contact-title p{
        font-size: 16px;
        margin-top: 0;
    }

    .phone-box{
        margin-top: 20px;
        display: flex;
        flex-direction: column;
        width: 100%;
        align-items: center;
    }
}