.cotizar-btn{
    background: #fed403;
    border: none;
    border-radius: 5px;
    font-weight: bold;
    text-decoration: none;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 150px;
    height: 50px;
    font-size: 22px;
    color: black;
}

.cotizar-btn-md{
    background: #fed403;
    border: none;
    border-radius: 5px;
    font-weight: bold;
    text-decoration: none;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 130px;
    height: 40px;
    font-size: 20px;
    color: black;
}

.cotizar-btn-s{
    background: #fed403;
    border: none;
    border-radius: 5px;
    font-weight: bold;
    text-decoration: none;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 120px;
    height: 34px;
    font-size: 16px;
    color: black;
}

.cotizar-btn-phone{
    background: #fed403;
    border: none;
    border-radius: 5px;
    font-weight: bold;
    text-decoration: none;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 40px;
    font-size: 20px;
    color: black;
}
