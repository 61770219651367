.form {
    width: 100%;
    height: 100%;
    background-color: white;
    display: flex;
    justify-content: center;
    border-radius: 5px;
    box-shadow: rgba(0, 0, 0, 0.24) 0 3px 8px;
}

.form-section{
    width: 90%;
    height: 100%;
}

.form h1 {
    font-size: 40px;
    font-weight: lighter;
    width: 200px;
    padding-top: 30px;
}

.form p {
    font-size: 20px;
    width: 65%;
    color: #636363;
    padding-top: 20px;
}

.inputs-container {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.input-container {
    width: 100%;
    display: flex;
    justify-content: space-between;
    margin-top: 20px;
}

.form input {
    width: 47%;
    height: 40px;
    background-color: #E4E4E4;
    border-radius: 5px;
    border: 1px solid #dadada;
    padding-left: 10px;
    font-size: 16px;
    font-family: Arial, sans-serif;
}

.area {
    width: 98%;
    margin-top: 20px;
    background-color: #E4E4E4;
    border: 1px solid #dadada;
    height: 150px;
    resize: none;
    padding-left: 10px;
    padding-top: 10px;
    font-size: 16px;
    font-family: Arial, sans-serif;
}

.send-button {
    color: white;
    font-size: 18px;
    width: 100%;
    height: 40px;
    margin-top: 20px;
    border: none;
    border-radius: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.send-button:hover{
    color: white;
}

.form-icons{
    display: flex;
    margin-top: 30px;
    justify-content: space-between;
    width: 62%;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
    .form input {
        width: 45%;
    }

    .area{
        height: 120px;
    }

    .send-button {
        margin-top: 20px;
    }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
    .form {
        height: auto;
        padding-bottom: 20px;
    }

    .form h1 {
        font-size: 36px;
    }

    .form p {
        font-size: 18px;
        padding-top: 20px;
    }

    .form input {
        width: 45%;
    }

    .area {
        width: 98%;
        margin-top: 20px;
        height: 120px;
        padding-left: 10px;
        padding-top: 10px;
        font-size: 16px;
    }

    .send-button {
        margin-top: 14px;
    }
}

@media only screen and (min-width: 330px) and (max-width: 575px) {
    .form {
        height: auto;
        padding-bottom: 20px;
    }

    .form h1 {
        font-size: 34px;
    }

    .form p {
        font-size: 18px;
        padding-top: 20px;
        width: 90%;
    }

    .form input {
        width: 43%;
    }

    .area {
        width: 98%;
        margin-top: 20px;
        height: 120px;
        padding-left: 10px;
        padding-top: 10px;
        font-size: 16px;
    }

    .send-button {
        margin-top: 14px;
        font-size: 16px;
    }
}

@media only screen and (max-width: 329px) {
    .form {
        height: auto;
        padding-bottom: 20px;
    }

    .form h1 {
        font-size: 34px;
    }

    .form p {
        font-size: 18px;
        padding-top: 20px;
        width: 90%;
    }

    .form input {
        width: 43%;
    }

    .area {
        width: 98%;
        margin-top: 20px;
        height: 120px;
        padding-left: 10px;
        padding-top: 10px;
        font-size: 16px;
    }

    .send-button {
        margin-top: 14px;
        font-size: 16px;
    }
}

