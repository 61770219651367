.services-rep-container{
    width: 100%;
    height: 750px;
    background-color: #FAFAFA;
    display: flex;
    justify-content: center;
    padding-bottom: 80px;
}

.services-rep-section{
    width: 80%;
    height: 100%;
    display: flex;
    justify-content: space-between;
}

.title-services{
    width: 30%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.title-services h1{
    font-weight: lighter;
    font-size: 56px;
}

.title-services p{
    font-size: 20px;
    margin-top: 20px;
    text-align: justify;
    width: 80%;
    margin-bottom: 30px;
}

.services-desc{
    width: 60%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

@media only screen and (min-width: 2000px){
    .services-desc{
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
    }
}

@media only screen and (min-width: 1200px) and (max-width: 1399px){
    .title-services h1{
        font-size: 56px;
    }

    .title-services p{
        font-size: 20px;
        width: 100%;
    }
}

@media only screen and (min-width: 992px) and (max-width: 1199px){
    .title-services h1{
        font-size: 56px;
    }

    .title-services p{
        font-size: 20px;
        width: 100%;
    }
}

@media only screen and (min-width: 768px) and (max-width: 991px){
    .services-rep-section{
        flex-direction: column;
    }

    .title-services{
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        height: 30%;
    }

    .title-services h1{
        font-weight: lighter;
        font-size: 50px;
    }

    .title-services p{
        font-size: 18px;
        margin-top: 10px;
        text-align: center;
        width: 80%;
        margin-bottom: 20px;
    }

    .services-desc{
        width: 100%;
        height: 66%;
        display: flex;
        margin-top: 50px;
        flex-direction: column;
        justify-content: flex-start;
    }
}

@media only screen and (min-width: 576px) and (max-width: 767px){
    .services-rep-container{
        height: auto;
        padding-bottom: 20px;
    }

    .services-rep-section{
        flex-direction: column;
    }

    .title-services{
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        height: 30%;
    }

    .title-services h1{
        font-weight: lighter;
        font-size: 50px;
    }

    .title-services p{
        font-size: 18px;
        margin-top: 10px;
        text-align: center;
        width: 80%;
        margin-bottom: 20px;
    }

    .services-desc{
        width: 100%;
        height: 70%;
        margin-top: 50px;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
    }
}

@media only screen and (min-width: 330px) and (max-width: 575px){
    .services-rep-container{
        height: auto;
        padding-bottom: 20px;
    }

    .services-rep-section{
        flex-direction: column;
    }

    .title-services{
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        height: 38%;
        margin-bottom: 20px;
    }

    .title-services h1{
        font-weight: lighter;
        font-size: 44px;
        width: 100%;
        text-align: center;
        margin-bottom: 10px;
    }

    .title-services p{
        font-size: 18px;
        margin-top: 20px;
        text-align: center;
        width: 100%;
        margin-bottom: 30px;
    }

    .services-desc{
        width: 100%;
        height: 60%;
        display: flex;
        margin-top: 20px;
        flex-direction: column;
        justify-content: flex-start;
    }
}

@media only screen and (max-width: 329px){
    .services-rep-section{
        flex-direction: column;
    }

    .title-services{
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        height: 38%;
        margin-bottom: 30px;
    }

    .title-services h1{
        font-weight: lighter;
        font-size: 44px;
        width: 100%;
        text-align: center;
        margin-bottom: 0;
    }

    .title-services p{
        font-size: 18px;
        margin-top: 0;
        text-align: center;
        width: 100%;
        margin-bottom: 20px;
    }

    .services-desc{
        width: 100%;
        height: 60%;
        margin-top: 20px;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
    }
}
