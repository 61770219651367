.admin-container{
    width: 100%;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
}

.admin-section{
    width: 80%;
    height: 100%;
    display: flex;
    flex-direction: column;
}

.admin-section h1{
    margin-top: 50px;
    font-size: 38px;
    margin-bottom: 50px;
}

.taskbar{
    height: 5%;
    width: 100%;
    margin-bottom: 10px;
}

.taskbar-options{
    display: flex;
    color: #1977F3;
    justify-content: space-between;
    margin-bottom: 6px;
}


.taskbar-line{
    height: 1px;
    width: 100%;
    background-color: black;
}

.messages{
    width: 100%;
    height: 70%;
    overflow: scroll;
    margin-top: 30px;
}

@media only screen and (max-width: 576px){
    .admin-section h1{
        font-size: 30px;
        margin-bottom: 20px;
    }
}