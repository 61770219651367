.footer-container{
    height: 200px;
    width: 100%;
    background-color: #000000;
    display: flex;
    justify-content: center;
    border-top: 1px solid #b7b7b7;
}

.footer-section{
    width: 80%;
    height: 100%;
    display: flex;
    flex-direction: column;
}

.footer-titles{
    display: flex;
    justify-content: center;
    height: 30%;
}

.footer-section p{
    color: #ffffff;
}

.footer-enlaces{
    margin-top: 30px;
    height: 65%;
    display: flex;
    justify-content: space-evenly;
}

.footer-enlaces-1{
    height: 60%;
    width: 50%;
}

.footer-enlaces-2{
    height: 60%;
    width: 50%;
    display: flex;
    justify-content: center;
}

.footer-links-space{
    display: flex;
    height: 100%;
    flex-direction: column;
    justify-content: space-between;
}

.footer-links{
    color: #ffffff;
    text-decoration: none;
}